<template>
  <div class="wrapper">
    <admin-title :title="$route.params.id ? '编辑账单' : '添加账单'"></admin-title>
    <a-divider />
    <el-form ref="form" inline :model="formData" label-width="100px">
      <el-form-item label="客户姓名">
        <el-input v-model="model" placeholder="客户姓名"></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-select style="width:190px" v-model="model" clearable placeholder="请选择">
          <el-option v-for="item in 3" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流水号">
        <el-input v-model="model" placeholder="流水号"></el-input>
      </el-form-item>
      <el-form-item label="期数">
        <el-input v-model="model" placeholder="期数"></el-input>
      </el-form-item>
      <el-form-item label="账期">
        <el-input v-model="model" placeholder="账期"></el-input>
      </el-form-item>
      <el-form-item label="金额">
        <el-input v-model="model" placeholder="金额"></el-input>
      </el-form-item>
      <el-form-item label="逾期金额">
        <el-input v-model="model" placeholder="逾期金额"></el-input>
      </el-form-item>
      <el-form-item label="逾期时间">
        <el-input v-model="model" placeholder="逾期时间"></el-input>
      </el-form-item>
      <el-form-item label="当前状态">
        <el-select style="width:190px" v-model="model" clearable placeholder="请选择">
          <el-option v-for="item in 3" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款时间">
        <el-date-picker style="width:190px" v-model="value1" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="平台">
        <el-select style="width:190px" v-model="model" clearable placeholder="请选择">
          <el-option v-for="item in 3" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商户">
        <el-select style="width:190px" v-model="model" clearable placeholder="请选择">
          <el-option v-for="item in 3" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户经理">
        <el-input v-model="model" placeholder="客户经理"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="" @click="$router.back()">返回</el-button>
        <el-button type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AddEdit',

  data() {
    return {
      formData: {}
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    width: 1000px;
    .el-form-item {
      width: 480px;
      &:last-child {
        width: 600px;
      }
    }
  }
}
</style>
